export const initialState = {
  widgetSource: null,
  courseInterest: '',
  eventData: null,
  brochureLink: null,
  placementReportLink: null,
  showHeader: false,
  utmRefCode: null,
  showStripBanner: false,
  upcomingClass: null,
  isOnboardingFormFilled: false,
  nextCohort: null,
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_WIDGET_SOURCE':
      return {
        ...state,
        widgetSource: payload,
      };
    case 'SET_COURSE_INTEREST':
      return {
        ...state,
        courseInterest: payload,
      };
    case 'SET_EVENT_DATA':
      return {
        ...state,

        eventData: payload,
      };
    case 'SET_BROCHURE_LINK':
      return {
        ...state,
        brochureLink: payload,
      };
    case 'SET_PLACEMENT_REPORT_LINK':
      return {
        ...state,
        placementReportLink: payload,
      };
    case 'SET_HEADER':
      return {
        ...state,
        showHeader: payload,
      };
    case 'SET_UTM_REF_ID':
      return {
        ...state,
        utmRefCode: payload,
      };
    case 'SET_STRIP_BANNER':
      return {
        ...state,
        showStripBanner: payload,
      };
    case 'SET_DEMO_CLASS':
      return {
        ...state,
        upcomingClass: payload,
      };
    case 'SET_ONBOARDING_FORM':
      return {
        ...state,
        isOnboardingFormFilled: payload,
      };
    case 'SET_COHORT':
      return {
        ...state,
        nextCohort: payload,
      };
    default:
      return state;
  }
};
