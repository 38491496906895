import dynamic from 'next/dynamic';
import Footer from '../footer';
import { getUtmQueryString } from '@lib/getUtmQueryString';
import { useRouter } from 'next/router';
import BreadCrumb from '../bytes/bread-crumb/BreadCrumb';
import cn from 'classnames';
import BytesWidget from '../bytes/BytesWidget';
import { checkifUGCourse, extractHexCode, generateSixDigitHexaCode } from '@utils/helper';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useStore } from '@globalStore/storeProvider';
import { getImgUrl } from '@utils/functions/getImgUrl';
import LeadCapturePopup from '../widgets/LeadCapturePopup';
import WhatsAppBanner from '@components/local/WhatsAppBanner';
import { COURSES_SLUGS } from '@lib/constants';
import EventPopup from '../bytes/EventPopup';
import { captureUtm } from '@lib/api';

const ContactModal = dynamic(() => import('@shared/widgets/ContactModal'));
const Header = dynamic(() => import('@shared/header/HeaderComponent'));
const DynamicStripBanner = dynamic(() => import('@shared/header/DynamicStripBanner'));

export default function PageComponent({ children, globalProps, coursesSummary }) {
  const { showHeader } = useStore();
  const router = useRouter();
  const { query } = router;
  const queryString = getUtmQueryString(query);
  const Path = router.asPath;
  const isUgCourse = checkifUGCourse(query?.degreeSlug);
  const dispatch = useDispatch();

  const hexCode = extractHexCode(router.asPath) || [];

  let showRequestCallback = true;
  let isLandingPages = false;
  let hideWhatsAppBanner = false;
  let showBreadCrumbs = true;
  let isBytesPage =
    router.pathname === '/bytes' ||
    Path.startsWith('/bytes/articles/') ||
    router.pathname === '/bytes/tutorials/[slug]';

  let showEventWidget = false;
  let showStripBanner = true;
  let darkBreadcrumb = false;
  let isScholarship =
    router.asPath === '/' ||
    router.asPath === '/degrees/masters-in-computer-science-data-science-ai' ||
    router.asPath === '/degrees/masters-in-computer-science-cloud-computing';

  let isDemoClass = true;
  let isPlacement =
    router.pathname === '/success-stories' || router.pathname === '/placement-statistics'
      ? true
      : false;

  if (
    ['/landing-page/', '/hire-from-us', '/euro-scholarship', '/announcement'].some(
      (path) => router.pathname === path
    )
  ) {
    showRequestCallback = false;
  }

  if (['/landing-page/'].some((path) => router.asPath.startsWith(path))) isLandingPages = true;

  if (
    [
      '/bytes',
      '/discord',
      '/degrees',
      '/courses',
      '/placement-statistics',
      '/online-compiler',
      '/hire-from-us',
    ].some((path) => router.asPath.startsWith(path))
  ) {
    hideWhatsAppBanner = true;
  }

  const hexaCode = hexCode.length > 0 ? hexCode.pop() : '';

  if (router.pathname === '/' || (hexaCode.includes('-') && hexaCode.length > 10))
    showBreadCrumbs = false;

  if (
    router.pathname?.startsWith('/bytes') ||
    router.pathname?.startsWith('/degrees') ||
    router.pathname?.startsWith('/community')
  ) {
    showEventWidget = true;
  }

  if (
    isUgCourse ||
    [
      ('/bytes/articles/[slug]', '/events/master-class/[slug]'),
      '/euro-scholarship',
      '/hire-from-us',
      '/announcement',
    ].some((path) => router.pathname === path)
  ) {
    showStripBanner = false;
  }

  if (router.pathname === '/_error') {
    showEventWidget = false;
    showRequestCallback = false;
    showBreadCrumbs = false;
    hideWhatsAppBanner = true;
    showStripBanner = false;
  }

  if (
    router.pathname?.startsWith('/degree') ||
    router.pathname?.startsWith('/courses') ||
    router.pathname.startsWith('/bytes/cheat-sheet') ||
    ['/placement-statistics', '/success-stories', '/hire-from-us', '/refer-and-earn'].includes(
      router.pathname
    )
  ) {
    darkBreadcrumb = true;
  }

  const about = globalProps.AboutHeader || [];
  const footerData = globalProps || {};
  const courses = footerData.Footer.filter((item) => item.slug === 'courses');
  const degrees = footerData.Footer.filter((item) => item.slug === 'degrees');

  useEffect(() => {
    dispatch({ type: 'SET_WIDGET_SOURCE', payload: null });
    sessionStorage.removeItem('sourceType');
  }, [router]);

  useEffect(() => {
    dispatch({
      type: 'SET_PLACEMENT_REPORT_LINK',
      payload: getImgUrl(globalProps?.placementReport),
    });
  }, []);

  const utmRefCode = useMemo(generateSixDigitHexaCode, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('touchstart', setUtmRefId, { once: true, capture: true });
      window.addEventListener('mousemove', setUtmRefId, { once: true });

      let sectionId = router.asPath?.split('#')[1];

      if (sectionId?.includes('?')) {
        sectionId = sectionId?.split('?')[0];
      }

      const section = document.getElementById(decodeURI(sectionId));

      section?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  useEffect(() => {
    if (router.isReady && typeof window !== 'undefined') {
      window.addEventListener('touchstart', setUtmRefId, { once: true, capture: true });
      window.addEventListener('mousemove', setUtmRefId, { once: true });
    }
  }, [router.isReady]);

  async function setUtmRefId() {
    const isLocalKeyExist = window?.localStorage.getItem('utmReferenceId');

    let utmCode = utmRefCode;

    if (isLocalKeyExist) {
      utmCode = isLocalKeyExist;
      window?.localStorage.setItem('utmReferenceId', utmCode);
    }

    dispatch({ type: 'SET_UTM_REF_ID', payload: utmCode });

    if (!router.query.utm_source) return;

    try {
      await captureUtm({ ...router.query, refId: utmCode });
    } catch (error) {
      console.error('HOME', error);
    }
  }

  return (
    <>
      {!isLandingPages && (
        <Header
          about={about}
          courses={courses}
          degrees={degrees}
          logo={globalProps.newCompanyLogo}
          queryString={queryString}
          promotionHeader={globalProps.PromotionHeader}
          coursesSummary={coursesSummary}
        />
      )}

      {showStripBanner && (
        <DynamicStripBanner
          isDemoClass={isDemoClass}
          isPlacement={isPlacement}
          isEuroScholarship={isScholarship}
        />
      )}
      <div className={cn({ 'mt-14': showHeader })}>
        {showBreadCrumbs && (
          // isCoursePage
          <div
            className={cn('relative z-10 pt-5 pl-3 lg:pl-16', {
              'bg-black': isBytesPage || darkBreadcrumb,
              'mt-12': showHeader,
            })}
          >
            <BreadCrumb isBytesPage={isBytesPage || darkBreadcrumb} />
          </div>
        )}
        {children}
        {!isLandingPages && showRequestCallback && (
          <ContactModal showRandromizePopup={isDemoClass} />
        )}
        {showEventWidget && <EventPopup />}
        {/* <DiscordWidget /> */}
        {!hideWhatsAppBanner && <WhatsAppBanner />}
        <LeadCapturePopup />
        <Footer
          data={footerData}
          coursesSummary={coursesSummary}
          logo={globalProps.newCompanyLogoDark}
        />
      </div>
    </>
  );
}
