import React, { useEffect } from 'react';

import useMediaQuery from '@utils/hooks/useMediaQuery';
import cn from 'classnames';
import RCBSuccessScreen from './RCBSuccessScreen';

import {
  ApplicationFormWithBranding,
  ApplicationFormWithHeader,
} from '../ApplicationForm/ApplicationFormVariant';

const { formTitle, formDesc, buttonCTA } = {
  formTitle: 'Talk to our Admissions Team',
  formDesc: 'Get all your questions answered by our admissions team',
  buttonCTA: 'Talk to Admission Team',
};

export function NewRCBForm({ closeModal, source, formContainerStyle, containerStyle }) {
  return (
    <ApplicationFormWithHeader
      closeModal={closeModal}
      source={source}
      formTitle={formTitle}
      formDesc={formDesc}
      SuccessScreen={RCBSuccessScreen}
      buttonCTA={buttonCTA}
      formContainerStyle={formContainerStyle}
      container={containerStyle}
    />
  );
}

export function NewRCBFormWithBranding({ closeModal, source }) {
  return (
    <ApplicationFormWithBranding
      closeModal={closeModal}
      source={source}
      formTitle={formTitle}
      formDesc={formDesc}
      SuccessScreen={RCBSuccessScreen}
      buttonCTA={buttonCTA}
    />
  );
}

export function NewRCBFormWidget({ closeWidget, asModal = false, source }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const isMobile = useMediaQuery('(max-width: 767.98px)');

  return (
    <section
      className={cn(
        'fixed inset-0 z-[1111] flex h-full w-full cursor-pointer items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm md:p-[24px]'
        // {
        //   'items-end justify-end': !isMobile && !asModal,
        //   'items-center justify-center backdrop-blur-sm': isMobile || asModal,
        // }
      )}
      onClick={closeWidget}
    >
      <div
        className={cn({ 'w-2/6': !isMobile, 'px-[16px]': isMobile })}
        onClick={(e) => e.stopPropagation()}
      >
        <NewRCBFormWithBranding closeModal={closeWidget} source={source} />
      </div>
    </section>
  );
}
