import React, { useState, Fragment, useMemo, useEffect } from 'react';
import Image from 'next/image';
import { Tab } from '@headlessui/react';
import moment from 'moment';
import BannerCard from '../../shared/shared-card/BannerCard';
import EventCard from './EventCard';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Loader from '@components/reuseable/loader';
import { useStore } from '@globalStore/storeProvider';
import cn from 'classnames';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function EventList({ events, workshops }) {
  const [isLoading, setLoading] = useState(true);
  const { showStripBanner } = useStore();

  const [categories, setCategories] = useState({
    'Upcoming Events': [],
    'Past Events': [],
  });

  useEffect(() => {
    if (!events.data?.length) return null;

    const { upcomingEvents, pastEvents } = getEvents(events.data, isLoading, setLoading);

    setCategories({
      'Upcoming Events': upcomingEvents,
      'Past Events': pastEvents,
    });
  }, [events]);

  if (isLoading || categories['Past Events'].length === 0) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="mx-auto w-auto py-8 sm:px-0 lg:px-8">
      <Tab.Group>
        <Tab.List
          className={`bg-blue-900/20 sticky ${
            showStripBanner ? 'top-[12.3rem] md:top-0' : 'top-[3.5rem]'
          } z-30 flex space-x-1 rounded-xl bg-gray-6 p-4 md:relative md:top-0`}
          style={{ background: '#F0F0F0' }}
        >
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'text-blue-700 btn w-full rounded-lg py-2.5 font-satoshi-medium text-base font-medium  uppercase leading-5',
                  'ring-offset-blue-400 ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
                  selected ? 'bg-red text-white shadow' : 'text-blue-100'
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        {/* <div className="mt-10 mb-5 hidden flex-row flex-wrap items-center justify-center gap-6 md:px-3 lg:flex">
          {eventCategories.map((event) => (
            <button
              key={event}
              className={cn('cursor-pointer rounded-md py-2 px-8 uppercase text-red shadow-md', {
                'bg-red/5': activeFilter === event,
                'bg-white': activeFilter !== event,
              })}
              onClick={() => handleEventFilter(event)}
            >
              {event}
            </button>
          ))}
        </div> */}
        {/* <div
          className={`sticky ${
            showStripBanner ? 'top-[16.7rem] md:top-0' : 'top-[8rem]'
          } z-30 w-full bg-white p-4 lg:hidden`}
        >
          <Menu as="div" className="text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-between rounded-md border px-4 py-2 font-satoshi-medium text-sm font-medium  text-black shadow-sm focus:outline-none">
                {activeFilter}
                <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="h-100px absolute mt-2 w-fit origin-top-left overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                style={{ border: '1px solid #E7E6E6' }}
              >
                <div className="py-1">
                  {eventCategories.map((item) => (
                    <Menu.Item key={item}>
                      {({ active }) => (
                        <a
                          className={classNames(
                            activeFilter === item
                              ? 'font-satoshi-bold font-bold  text-red'
                              : 'text-black',
                            'block px-4 py-2 text-sm'
                          )}
                          onClick={() => handleEventFilter(item)}
                        >
                          {item}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div> */}
        <Tab.Panels className="my-8">
          {Object.values(categories).map((events, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-offset-blue-400 ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2'
              )}
            >
              {events.length === 0 ? (
                <div className="flex flex-col items-center justify-center">
                  <Image
                    src="/assets/images/Group 1000002518.png"
                    alt=""
                    className="lg:max-h-sm lg:max-w-md"
                    height={400}
                    width={500}
                    loading="lazy"
                  />
                  Sorry, there are no events scheduled at the moment.
                </div>
              ) : (
                events.map((event, index) => (
                  <EventCard workshops={workshops} key={event.slug} {...event} />
                ))
              )}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

const getEvents = (events, isLoading, setLoading) => {
  const today = moment();

  const upcomingEvents = [];
  const pastEvents = [];

  events.forEach(function (event) {
    let {
      title,
      description,
      eventDate,
      startTime,
      endTime,
      eventImage,
      eventType,
      slug,
      registerLink,
      videoLink,
      instructor,
      recordedVideoLink,
    } = event.attributes;

    const formattedDate = moment(eventDate).format('MMMM Do YYYY, dddd');

    const eventId = registerLink?.match(/\d+/g)?.[0];

    if (today.isSameOrBefore(moment(eventDate + 'T' + endTime))) {
      upcomingEvents.push({
        slug,
        title,
        description,
        eventDate: formattedDate,
        startTime,
        endTime,
        eventImage,
        eventId,
        eventType,
        category: 'upcoming',
        dateAndTime: eventDate,
        registerLink,
        videoLink,
        instructor,
        recordedVideoLink,
      });
    } else {
      pastEvents.push({
        slug,
        title,
        description,
        eventDate: formattedDate,
        startTime,
        endTime,
        eventImage,
        eventId,
        eventType,
        videoLink,
        category: 'past',
        instructor,
        recordedVideoLink,
      });
    }
  });

  upcomingEvents.sort((a, b) => new Date(a.dateAndTime) - new Date(b.dateAndTime));

  if (isLoading && pastEvents.length > 0) {
    setLoading(false);
  }

  return { upcomingEvents, pastEvents };
};
